// 
// card.scss
//

.card {
    border: none;
    box-shadow: $card-box-shadow;
    margin-bottom: $grid-gutter-width;
    background-color: var(--#{$variable-prefix}card-bg-2);

    .header-title {
        // margin-bottom: 0.5rem;
        margin-bottom: 0;
        text-transform: uppercase;
        letter-spacing: 0.02em;
        font-size: $font-size-base;
        margin-top: 0;
    }
    .card-drop {
        font-size: 20px;
        // line-height: 0;
        color: inherit;
    }
    .card-widgets {
        float: right;
        height: 16px;
        > a {
            color: inherit;
            font-size: 18px;
            display: inline-block;
            line-height: 1;

            &.collapsed {
                i {
                    &:before {
                        content: "\F0415";
                    }
                }
            }
        }
    }
}


// Card title / Card Header
.card-title,
.card-header {
    margin-top: 0;
}


//Card disable loading (Custom Cards)
.card-disabled {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: $card-border-radius;
    background: #{rgba(map-get($grays, "100"), 0.8)};
    cursor: progress;

    .card-portlets-loader {
        background-color: #{map-get($grays, "900")};
        animation: rotatebox 1.2s infinite ease-in-out;
        height: 30px;
        width: 30px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: - ($grid-gutter-width * 0.5);
        margin-top: - ($grid-gutter-width * 0.5);
    }
}

@keyframes rotatebox {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}

// Pricing card

.card-pricing {
    position: relative;
    
    .card-pricing-plan-name {
        padding-bottom: 20px;
    }

    .card-pricing-icon {
        font-size: 22px;
        box-shadow: $box-shadow;
        height: 60px;
        display: inline-block;
        width: 60px;
        line-height: 56px;
        border-radius: 50%;
    }

    .card-pricing-price {
        padding: 30px 0 0;

        span {
            font-size: 40%;
            color: #{map-get($grays, "600")};
            letter-spacing: 2px;
            text-transform: uppercase;
        }
    }
    .card-pricing-features {
        color: #{map-get($grays, "600")};
        list-style: none;
        margin: 0;
        padding: 20px 0 0 0;

        li {
            padding: 15px;
        }
    }
}

.card-pricing-recommended {

    @include media-breakpoint-up(lg) {
        margin-top: -1.9375rem;
    }

    .card-pricing-plan-tag {
        background-color: rgba($danger,0.2);
        color: $danger;
        padding: 5px 0;
        font-weight: 700;
        border-radius: .25rem .25rem 0 0;
        margin: -1.5rem -1.5rem 1.5rem -1.5rem;
    }
}

// Custom card height

.card-h-100 {
    height: calc(100% - #{$grid-gutter-width});
}



// Dark mode
body[data-layout-color="dark"] {
    .card-pricing {
        .card-pricing-price span, .card-pricing-features {
            color: map-get($dark-grays, "600");
        }
    }
    .card-disabled {
        background: #{rgba(map-get($dark-grays, "100"), 0.8)};
        .card-portlets-loader {
            background-color: #{map-get($dark-grays, "900")};
        }
    }
}


$transition: all 0.3s cubic-bezier(.25,.8,.25,1);
/* Card */
$card-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
$card-shadow-hover: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);

.scard {
  transition: $transition;
  box-shadow: $card-shadow;  
  background-color: var(--#{$variable-prefix}card-bg-2);
  margin: 20px 10px;
  cursor: pointer;
  &:hover {
    box-shadow: $card-shadow-hover;
  }
  padding: 0px
}

/* Selectable */
$check-size: 20px;
$selected-color: #44aadd;

.scard .selectable {
  position: relative;
  transition: $transition;
  border: 4px solid transparent;
  .check {
    transition: $transition;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    width: $check-size;    
    height: $check-size;
    &:before {
      transition: $transition;
      content: '';
      border: $check-size solid;
      border-color: transparent;
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
    }
    .checkmark {
      display: block;
      font: $check-size sans-serif;
      line-height: $check-size;
      text-align: center;
      color: transparent;  
    }
  }
  
  &.selected {
    border-color: $selected-color;
    height: 100%;
    .check {
      &:before {
        border-color: $selected-color $selected-color rgba(0,0,255,0) rgba(255,0,0,0);
      }
      .checkmark {
        color: #fff;
      }
    }
  }
}

.scard .content {
  .title, .description { 
    margin: 0; 
    padding: 4px
  }
  padding: 10px;
}

.column {
  & > .title {
    text-align: center;
  }
  float: left;
  width: 50%;
}

button.scard {
  display: block;
  cursor: pointer;
  width: 180px;
  margin: 20px auto;
  text-align: center;
  padding: 16px;
  border-color: transparent;
  border-radius: 10px;
  background: $selected-color !important;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  outline: none;
  &:focus{
    box-shadow: $card-shadow;
  }
}